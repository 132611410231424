<template>
  <div class="page-login-desktop">
    <v-card flat>
      <v-card-title class="d-flex align-center justify-space-between">
        <div class="font-weight-bold text-body-1">
          ارسال پیام به همه کاربران
        </div>
        <v-btn
            @click="$router.back()"
            text color="main" large>
          بازگشت
          <v-icon class="mr-2">
            mdi-arrow-left
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col :cols="12">
            <v-text-field
                label="عنوان پیام"
                outlined
                hide-details
                v-model="message.title"
            />
          </v-col>
          <v-col :cols="12">
            <v-textarea
                label="متن پیام"
                outlined
                hide-details
                v-model="message.body"
            />
          </v-col>

          <v-col :cols="12">
            <v-switch
                inset
                label="ارسال پیامک"
                hide-details
                v-model="message.withsms"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
            class="white--text"
            large
            color="main"
            :loading="message.loading"
            :disabled="message.loading || !(message.title && message.body)"
            @click="submitMessage"
        >
          ارسال پیام
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import {sendMessageToAll} from "@Newfiling/module-message/src/api";

export default {
  name: 'DesktopLogin',


  data() {
    return {
      message: {
        title: "",
        body: "",
        withsms: false,
        loading: false,
      }
    }
  },

  beforeMount() {
    this.$store.commit('Breadcrumb/setBreadcrumb', [
      {
        text: 'پنل مدیریت نیوفایل',
        disabled: false,
        href: '/panel/dashboard',
      },
      {
        text: 'ارسال پیام به همه کاربران',
        disabled: true,
        href: '/panel/messages/new-message',
      },
    ])
  },


  methods: {
    async submitMessage() {
      this.message.loading = true;
      try {
        await sendMessageToAll({
          "Title": this.message.title,
          "Messagetext": this.message.body,
          "Withsms": this.message.withsms,
        });
        this.$toast.success('پیام شما به همه کاربران ارسال شد.');
        this.message = {
          ...this.message,
          title: "",
          body: "",
          withsms: false,
          loading: false,
        }
      } catch (e) {
        console.log(e)
      }
      this.message.loading = false;
    },
  }

}
</script>
